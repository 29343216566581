// Import vendors ----------------------------------------------------------------------------------
import { injectable } from 'inversify';
import { EventBus, createEventDefinition } from 'ts-bus';
import { onBeforeUnmount } from '@vue/composition-api';
// Import factories --------------------------------------------------------------------------------
import { ModuleFactory } from '../../factories/Module.factory';
// Import types ------------------------------------------------------------------------------------
import type { BusEvent, EventCreatorFn } from 'ts-bus/types';
import type { Route } from 'vue-router';
import type { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import type { Patient } from '@/plugins/podocore/repositories/patients.repository';
import type { Office } from '@/plugins/podocore/repositories/offices.repository';
import type { Activity } from 'xstate';
// Export / declare types --------------------------------------------------------------------------
export type DialogId =
  | 'terms-and-conditions'
  | 'auth-mfa-activation'
  | 'patient-create'
  | 'patient-update'
  | 'patient-delete'
  | 'patient-pathology'
  | 'analysis.filter'
  | 'analysis-rename'
  | 'analysis-delete'
  | 'analysis-export-pdf'
  | 'analysis-comment-update'
  | 'analysis-conditions-walking-aids-update'
  | 'analysis-conditions-pains-update'
  | 'analysis-conditions-shoes-type-update'
  | 'download-mobile-app'
  | 'office-create'
  | 'office-update'
  | 'office-delete'
  | 'udi'
  | 'notification'
  | 'analysis-filter-custom-select'
  | 'workspace-create'
  | 'workspace-invite'
  | 'saas-checkout'
  | 'media-delete'
  | 'update'
  | 'pdf-comments'
  | 'pdf-actions'
  | 'podomigration'
  | 'podomigration-link';
// -------------------------------------------------------------------------------------------------

/**
 * Bus module
 */
@injectable()
export class BusModule extends ModuleFactory {
  private readonly _bus;
  private readonly _events;

  constructor() {
    super();

    this._bus = new EventBus();

    this._bus.emitter.setMaxListeners(30);

    this._events = BusModule.createEvents();

    this.subscribe = this.subscribe.bind(this);
    this.publish = this.publish.bind(this);
  }

  /**
   * Create events
   */
  private static createEvents() {
    // Router
    const routeEnter = createEventDefinition<{
      to: Route;
      from: Route;
    }>()('route.enter');
    const routeLeave = createEventDefinition<{
      to: Route;
      from: Route;
    }>()('route.leave');
    const routeBypass = createEventDefinition<{
      name: string;
    }>()('route.bypass');

    // Dialog
    const openDialog = createEventDefinition<{
      id: DialogId;
      meta?: Record<string, unknown>;
    }>()('dialog.open');
    const dialogOpened = createEventDefinition<{
      id: DialogId;
      meta?: Record<string, unknown>;
    }>()('dialog.opened');
    const closeDialog = createEventDefinition<{
      id: DialogId;
      meta?: Record<string, unknown>;
    }>()('dialog.close');
    const dialogClosed = createEventDefinition<{
      id: DialogId;
      meta?: Record<string, unknown>;
    }>()('dialog.closed');

    // Patients
    const patientLoaded = createEventDefinition<{
      patient: Entity<Patient>;
    }>()('patient.loaded');
    const patientUnloaded = createEventDefinition<{
      patientCuid: Entity<Patient>['cuid'];
    }>()('patient.unloaded');
    const patientSwitched = createEventDefinition<{
      patientCuid: Entity<Patient>['cuid'];
    }>()('patient.switched');
    const patientCreated = createEventDefinition<{
      patientCuid: Entity<Patient>['cuid'];
    }>()('patient.created');
    const patientPatched = createEventDefinition<{
      patient: Entity<Patient>;
    }>()('patient.patched');
    const patientDeleted = createEventDefinition<{
      patientCuid: Entity<Patient>['cuid'];
    }>()('patient.deleted');

    // Offices
    const officeCreated = createEventDefinition<{
      officeCuid: Entity<Office>['cuid'];
    }>()('office.created');
    const officePatched = createEventDefinition<{
      officeCuid: Entity<Office>['cuid'];
    }>()('office.patched');
    const officeDeleted = createEventDefinition<{
      officeCuid: Entity<Office>['cuid'];
    }>()('office.deleted');

    // Analysis
    const analysisFilter = createEventDefinition<{
      filter: string;
      type: 'PATCH' | 'CREATE';
      name?: string;
      selectedCharts?: any;
      cuid?: string;
    }>()('analysis.filter');
    const analysisRenamed = createEventDefinition<{
      analysisCuid: Entity<Activity<any, any>>['cuid'];
      name: string;
    }>()('analysis.renamed');
    const analysisDeleted = createEventDefinition<{
      analysisCuid: Entity<Activity<any, any>>['cuid'];
    }>()('analysis.deleted');
    const analysisExportPrintSetIsPending = createEventDefinition<{
      value: boolean;
    }>()('analysis.export.print.set-is-pending');
    const analysisCommentCreated = createEventDefinition<{
      analysisCommentCuid: Entity<Activity<Comment, any>>['cuid'];
    }>()('analysis.comment.created');
    const analysisCommentPatched = createEventDefinition<{
      analysisCommentCuid: Entity<Activity<Comment, any>>['cuid'];
    }>()('analysis.comment.patched');
    const analysisCommentDeleted = createEventDefinition<{
      analysisCommentCuid: Entity<Activity<Comment, any>>['cuid'];
    }>()('analysis.comment.deleted');
    const analysisConditionsPatched = createEventDefinition<{
      analysisCuid: Entity<Activity<any, any>>['cuid'];
    }>()('analysis.conditions.patched');
    const activityRunningSelectedSegments = createEventDefinition<{
      selectedSegments: any;
    }>()('activity.results.selected-segments');

    const createNotification = createEventDefinition<{
      message: any;
    }>()('notification.created');

    const workspaceChanged = createEventDefinition()('workspace.changed');
    const workspaceCreated = createEventDefinition<{
      workspace: Entity<any>;
    }>()('workspace.created');
    const workspaceMembersInvited = createEventDefinition<{
      workspaceCuid: Entity<any>['cuid'];
    }>()('workspace.members.invited');
    const workspaceMembersDeleted = createEventDefinition<{
      workspaceCuid: Entity<any>['cuid'];
    }>()('workspace.members.deleted');

    // Media
    const mediaDeleted = createEventDefinition<{
      mediaCuid: string;
    }>()('media.deleted');

    // Podomigration
    const podomigrationLinked = createEventDefinition<{
      patientCuid: string;
    }>()('podomigration.linked');

    // PDF
    const pdfComments = createEventDefinition<{
      data: string;
    }>()('pdf.comments');
    const pdfActions = createEventDefinition<{
      data: string;
    }>()('pdf.actions');

    return {
      // Router
      routeEnter,
      routeLeave,
      routeBypass,
      // Dialog
      openDialog,
      dialogOpened,
      closeDialog,
      dialogClosed,
      // Patients
      patientLoaded,
      patientUnloaded,
      patientSwitched,
      patientCreated,
      patientPatched,
      patientDeleted,
      // Offices
      officeCreated,
      officePatched,
      officeDeleted,
      // Analysis
      analysisFilter,
      analysisRenamed,
      analysisDeleted,
      analysisExportPrintSetIsPending,
      analysisCommentCreated,
      analysisCommentPatched,
      analysisCommentDeleted,
      analysisConditionsPatched,
      activityRunningSelectedSegments,
      // Notifications
      createNotification,
      // Workspace
      workspaceChanged,
      workspaceCreated,
      workspaceMembersInvited,
      workspaceMembersDeleted,
      // Media
      mediaDeleted,
      // Podomigration
      podomigrationLinked,
      // PDF
      pdfComments,
      pdfActions
    };
  }

  /**
   * Subscribe to an event
   */
  public subscribe<T extends BusEvent>(
    subscription: EventCreatorFn<T>,
    handler: (event: T) => void
  ): () => void {
    return this._bus.subscribe(subscription, handler);
  }

  /**
   * Publish an event
   */
  public publish<T extends BusEvent>(event: T): void {
    this._bus.publish(event);
  }

  /**
   * Use event subscriber
   */
  public useEventSubscriber<T extends BusEvent>(
    subscription: EventCreatorFn<T>,
    handler: (event: T) => void
  ): () => void {
    // console.log('SUBSCRIBE >>', subscription.eventType);
    const unsubscribe = this._bus.subscribe(subscription, handler);
    onBeforeUnmount(() => {
      unsubscribe();
    });
    return unsubscribe;
  }

  get events(): ReturnType<typeof BusModule['createEvents']> {
    return this._events;
  }
}
